import React from 'react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import FallbackImage from '../../FallbackImage';
import { PoiThumb } from 'models/Poi';
import { ImageConversions } from 'models/PoiMedia';
import { getResizedThumbUrl } from 'utils/POIHelpers';
import { useMedia } from 'react-use';
SwiperCore.use([Navigation, Pagination]);

const PoiCardMedia = ({
    images,
    alt,
    className,
    size = 'thumb'
}: {
    images: PoiThumb[];
    alt: string;
    className?: string;
    size?: keyof ImageConversions;
}) => {
    const isSmall = useMedia('(max-width: 480px)', false);
    return (
        <div className="relative h-full">
            {images.length === 0 && <FallbackImage />}

            {images.length > 0 && (
                <Swiper
                    loop
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={!isSmall}
                    preloadImages={false}
                    pagination={{ clickable: true }}
                    cssMode
                    className={`h-full overflow-hidden ${className}`}
                >
                    {images.slice(0, 4).map((thumb, i) => (
                        <SwiperSlide key={i} className="w-full h-full max-w-full bg-gray-100">
                            <img
                                src={getResizedThumbUrl(thumb, size)}
                                loading="lazy"
                                alt={alt}
                                className="w-full h-full object-cover object-center"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
};

export default PoiCardMedia;
